import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {CssBaseline} from '@mui/material';
import {useGet} from '../../common/fetching';
import {useCartData} from '../cartUtils';
import Home from './Home';


const App = (props) => {
  const [cart, cartDispatch] = useCartData();
  const menuFetch = useGet('data/');
  useEffect(() => {menuFetch.send()}, []);
  return (
    <React.Fragment>
    <CssBaseline />
    <Switch>
      <Route path="/">
        <Home menuFetch={menuFetch} cart={cart} cartDispatch={cartDispatch} />
      </Route>
    </Switch>
    </React.Fragment>
  )
}
export default App;