import {useState, useEffect, useRef} from 'react';
import Cookies from 'js-cookie';

export const protocol = process.env.API_PROTOCOL;
export const host = process.env.API_HOST;
const apiPath = `${protocol}://${host}/api/store/`;
const contentPath = `${protocol}://${host}/api/content/`;
const cors = process.env.CORS === 'true'

const headers ={
  'Content-Type': 'application/json',
  'Accept': 'application/json'
}

const useFetchingBase = (methodName, url, fetchData=null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [shouldSend, setShouldSend] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [ok, setOk] = useState(null);

  const clear = () => {
    setLoading(false);
    setErrors(false);
    setData(false);
    setShouldSend(false);
    setStatusCode(null);
    setOk(null);
  }

  const send = () => {
    setShouldSend(true);
  }

  useEffect(() => {
    if (shouldSend) {
      setLoading(true);
      setStatusCode(null);
      setOk(null);
      if (methodName !== 'GET') {
        headers['X-CSRFToken'] = Cookies.get('csrftoken');
      }
      const fetcher = async () => {
        const init = {
          method: methodName,
          headers: headers,
          mode: 'cors',
          credentials: cors ? 'include' : 'same-origin'
        }
        if (fetchData) {init.body = JSON.stringify(fetchData);}
        try {
          const response = await fetch(url, init);
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            const fetchedData = await response.json();
            if (response.ok){
              setData(fetchedData);
              setErrors({});
            } else {
              let newFetchedData = fetchedData
              if (fetchedData.card_type) {  //if the error came from banquest
                newFetchedData = {card: fetchedData}
              }
              setErrors(newFetchedData);
            }
          }
          setStatusCode(response.status);
          setOk(response.ok);
        } catch(err) {
          if (err instanceof TypeError) {
            setOk(false);
            setStatusCode(418);
            setErrors(
              {network: 'Could not connect to server. Check your network connection.'}
            );
            console.error(err);
          } else {
            throw err;
          }
        } finally {
          setLoading(false);
          setShouldSend(false);
        }
      }
        fetcher();
    }
  }, [shouldSend,]);
  return {
    data: data, loading: loading, statusCode: statusCode,
    ok: ok, send: send, setData: setData, errors: errors};
}

export const useGetContent = path => useFetchingBase('GET', contentPath + path);

export const useGet = (path) => useFetchingBase('GET', apiPath + path);

export const usePost = (path, data) => useFetchingBase('POST', apiPath + path, data);

export const usePut = (path, data) => useFetchingBase('PUT', apiPath, + path, data);

export const usePatch = (path, data) => useFetchingBase('PATCH', apiPath + path, data);

export const useAuth = () => {
  const [formData, setFormData] = useState({});
  const [loggedIn, setLoggedIn] = useState(true);
  const url = `${protocol}://${host}/api/auth/`;
  const {data, statusCode, ok, loading, send} = useFetchingBase(
    'POST', `${url}login/`, formData
  );
  const logoutFetch = useFetchingBase('POST', `${url}logout/`, {});

  useEffect(() => {
    if (ok) {
      setLoggedIn(true)
    }
    else if (statusCode === 401) {
      setLoggedIn(false)
    }
  }, [ok, statusCode]);

  useEffect(() => {
    if (logoutFetch.ok) {
      setLoggedIn(false);
    }
  },
  [logoutFetch.ok]);

  const login = (email, password) => {
    setFormData({email: email, password: password});
    send();
  }

  return {
    loading: loading, loggedIn: loggedIn, statusCode: statusCode,
    setLoggedIn: setLoggedIn, login: login, logout: () => logoutFetch.send()
  };
}


export const useSSE = (loggedIn, path) => {
  const es = useRef({});
  const [message, setMessage] = useState(null);
  const [error, setError] = useState({});
  const [readyState, setReadyState] = useState('closed');
  const defaultPath = `${protocol}://${host}/events/`;
  const config = {withCredentials: cors};

  const clear = () => {
    setMessage(null);
    setError({});
  }

  useEffect(() => {
    if (loggedIn) {
      if (message || error) {
        clear();
      }
      es.current = new EventSource(`${defaultPath}${path || ''}`, config);
      es.current.onopen = () => {
        if (Object.keys(error).length) {
          setError({});
        }
      }
      es.current.onmessage = e => {
        setMessage(JSON.parse(e.data));
        if (Object.keys(error).length) {
          setError({});
        }
      }
      es.current.addEventListener( 'stream-error', e => {
          const errorData = JSON.parse(e.data);
          console.error(errorData);
          setError(errorData);
          es.current.close();
        });
      es.current.onerror = e => {
        setError(e);
        console.error(e);
      }
    } else {
      es.current.close();
    }
    if (es.current?.close) {
      return () => es.current?.close();
    }
  }, [loggedIn,]);

  useEffect(() => {
    if (es.current.readyState || es.current.readyState === 0) {
      let val = '';
      switch (es.current.readyState) {
        case 0:
            val = 'connecting'
          break;
        case 1:
          val = 'open'
          break;
        case 2:
          val = 'closed';
          break;
      }
      if (readyState !== val) {
        setReadyState(val);
      }
    }
  });

  return {
    message: message,
    error: error,
    readyState: readyState,
  }
}