import React from 'react';
import {Helmet} from 'react-helmet';

export const defaultTitle = "Murray's Takeout and Catering - Kosher food in Savannah";
export const defaultDescription = 'Kosher takeout and catering in Savannah';

const HelmetWrapper = ({title, description, image, url, canonical}) => {
  const newTitle = `${defaultTitle}${title ? ` - ${title}` : ''}`;
  return (
    <Helmet>
      {title && <title>{newTitle}</title>}
      {title && <meta property="og:title" content={newTitle}/>}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {url && <meta property="og:url" content={url} />}
      {image && <meta property="og:image" content={image} />}
      {canonical &&
        <link
          rel="canonical"
          href={`${window.location.origin}/storefront${canonical}`}
        />
      }
    </Helmet>
  );
}
export default HelmetWrapper;