import React, {useState, useRef, Suspense} from 'react';
import {Switch, Route, Link as RRLink} from 'react-router-dom';
import {Toolbar, Typography, SnackbarContent, Link, Button,
  CircularProgress, Badge, Avatar, Snackbar, Tab, Tabs} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Helmet} from 'react-helmet';
import styled from '@emotion/styled';
import {ErrorBoundary} from 'react-error-boundary';
import {protocol, host} from '../../common/fetching';
import {defaultTitle, defaultDescription} from './HelmetWrapper';
const Menu = React.lazy(() => import('./Menu'));
const Order = React.lazy(() => import('./Order'));
const HomePage = React.lazy(() => import('./HomePage'));
const Contact = React.lazy(() => import('./Contact'));
const FourOFour = React.lazy(() => import('./FourOFour'));
import JsonLD from './JsonLD';
import Cart from './Cart';
import ErrorFallback from './ErrorFallback';
import '../../../stylesheets/storefront/mainstyles.scss';

const url = `${protocol}://${host}`;

const initTabs = pathname => {
  if (pathname.includes('/menu')) {
    return 1;
  }
  if (pathname.includes('/contact')) {
    return 2;
  }
  return 0;
}

const DivRoot = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& a': {
    textDecoration: 'none',
  }
});

// background: center / cover no-repeat url(${url}/static/content/banner.webp);
const Hero = styled.header`
  background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.55)), url(${url}/static/content/banner.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.palette.primary.contrastText};
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 600px): {
    background-image: url(${url}/static/content/bannerm.webp);
  }
`

const DivFooter = styled.div`
  background-color: ${props => props.theme.palette.primary.dark};
  color: ${props => props.theme.palette.primary.contrastText};
  padding: 2rem;
`
const Home = ({menuFetch, cart, cartDispatch}) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [index, setIndex] = useState(initTabs(window.location.pathname));
  const toolbarRef = useRef();

  const handleCloseSuccess = e => setOpenSuccess(false);
  return (
    <div className="home">
      <Helmet>
        <title>{defaultTitle}</title>
        <meta property="og:title" content={defaultTitle} />
        <meta name="description" content={defaultDescription} />
        <meta property="og:description" content={defaultDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image" content={`${url}/static/content/banner.webp`}
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Hero>
        <div className="banner-content">
          <RRLink to="/" className="site-header">
            <Typography variant="h3" component="h1" align="center">
              Murray's Kosher Takeout and Catering
            </Typography>
          </RRLink>
          <Typography
            variant="subtitle1"
            display="inline"
            align="center"
            component="p"
          >
            A service of&nbsp;
          </Typography>
          <Link
            align="center"
            variant="subtitle1"
            display="inline"
            href="https://bbjsynagogue.com"
            sx={{
              color: '#ffffff',
            }}
          >
            Bnai Brith Jacob Synagogue
          </Link>
          <Typography variant="subtitle2" align="center" component="p">
            Certified Kosher under the supervision of Rabbi Avigdor Slatus;
            Rov of Bnai Brith Jacob Synagogue.
          </Typography>
        </div>
      <Button
        variant="contained"
        color="primary"
        aria-label="Scroll down"
        className="down-button-container"
        onClick={() => {
          toolbarRef.current.scrollIntoView({behavior: 'smooth'});
        }}
      >
        <KeyboardArrowDownIcon
          sx={{
            fontSize: '5rem',
            color: 'primary.light',
          }}
        />
      </Button>
      <Toolbar className="toolbar" ref={toolbarRef}>
        <Tabs
          className="tabs"
          component="nav"
          textColor="inherit"
          indicatorColor="secondary"
          value={index}
          onChange={(e, value) => {
            setIndex(value)
            toolbarRef.current.scrollIntoView({behavior: 'smooth'});
          }}
        >
          <Tab component={RRLink} to="/" label="Home" />
          <Tab component={RRLink} to="/menu" label="Menu" />
          <Tab component={RRLink} to="/contact" label="Contact Us" />
        </Tabs>
        <Badge
          className="cartlink"
          badgeContent={cart.quantity}
          color="secondary"
        >
          <Avatar
            component={RRLink}
            to="/cart"
            sx={{
              backgroundColor: 'secondary.main',
              height: 48, width: 48,
            }}
          >
            <ShoppingCartIcon titleAccess="Add shopping cart icon"/>
          </Avatar>
        </Badge>
      </Toolbar>
      </Hero>
    <div className="full-content">
    <div className="content">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<CircularProgress />}>
      <Switch>
        <Route path="/cart">
          {(!menuFetch.ok) ? <CircularProgress /> : (
            <Cart
              menu={menuFetch.data}
              cart={cart}
              cartDispatch={cartDispatch}
              showBreadcrumbs={true}
            />
          )}
        </Route>
        <Route path="/order">
          {!menuFetch.ok ? <CircularProgress /> : (
          <Order
            setSuccess={setOpenSuccess}
            menu={menuFetch.data}
            cart={cart} cartDispatch={cartDispatch}
            />
          )}
        </Route>
        <Route path={["/menu/category/:category", "/menu"]}>
          {(menuFetch.loading || !menuFetch.ok) ? (
            <CircularProgress />) : (
            <Menu
              menu={menuFetch.data}
              setSuccess={setOpenSuccess}
              cart={cart} cartDispatch={cartDispatch}
            />
          )}
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/" exact>
          <HomePage setIndex={setIndex} />
        </Route>
        <Route path="*"><FourOFour /></Route>
      </Switch>
      </Suspense>
      </ErrorBoundary>
        <Snackbar open={openSuccess} autoHideDuration={6000}
          onClose={handleCloseSuccess} message="Success!"
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      >
        <SnackbarContent
          sx={{backgroundColor: 'success.main'}}
          message="Success"
        />
      </Snackbar>
    </div>
  <DivFooter>
    <div>
      <Typography variant="body2">5444 Abercorn St.</Typography>
      <Typography variant="body2">Savannah, GA 31405</Typography>
      <Typography variant="body1">912-354-7721</Typography>
      <Link
        variant="body1"
        color="inherit"
        href="mailto:bbjacoboffice@gmail.com?subject=foodservice"
      >
        bbjacoboffice@gmail.com
      </Link>
    </div>
    </DivFooter>
    </div>
    {menuFetch.ok &&
      <JsonLD
        products={menuFetch.data.products}
        categories={menuFetch.data.categories}
      />}
    </div>
  );
}
export default Home;
