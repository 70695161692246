import React from 'react';

const protocol = process.env.API_PROTOCOL;
const host = process.env.API_HOST;
const origin = window.location.origin;
const image = `${protocol}://${host}/static/content/banner.webp`;

const serializeProduct = (product) => {
  return ({
    '@type': 'MenuItem',
    name: product.name,
    description: product.description,
    suitableForDiet: 'http://schema.org/KosherDiet',
    offers: {
      '@type': 'Offer',
      price: product.price,
      priceCurrency: 'USD',
    },
    image: product.image,
    url: `${origin}/menu/${product.slug}`,
  });
}

const serializeCategory = (category, products) => {
  const workingProducts = products.filter(
    product => product.category === category.slug
  );
  const productsSerialized = workingProducts.map(
    product => serializeProduct(product)
  );
  return {
    '@type': 'MenuSection',
    name: category.name,
    image: category.image,
    url: `${origin}/menu/category/${category.slug}`,
    description: category.description,
    hasMenuItem: productsSerialized,
  }
}
const serializeMenu = (categories, products) => {
  const workingCategories = categories.filter(
    category => category.slug !== 'full-menu'
  )
  const categoriesSerialized = workingCategories.map(
    category => serializeCategory(category, products)
  );
  return ({
    '@context': 'http://schema.org',
    '@type': 'restaurant',
    name: "Murray's Kosher Takeout and Catering",
    description: 'Kosher Catering and Takeout in Savannah',
    image: image,
    url: window.location.origin,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 32.026129965924234,
      longitude: -81.10836417031629,
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Savannah',
      addressRegion: 'GA',
      postalCode: '31405',
      streetAddress: '5444 Abercorn St'
    },
    telephone: '+19123547721',
    email: 'bbjacoboffice@gmail.com',
    servesCuisine: 'Kosher',
    hasMenu: {
      '@type': 'Menu',
      url: `${window.location.origin}/menu`,
      hasMenuSection: categoriesSerialized,
    }
  });
}


const JsonLD = ({categories, products}) => {
  const data = JSON.stringify(serializeMenu(categories, products));
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{__html: data}}
    />
  );
}
export default JsonLD;
