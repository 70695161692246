import React from 'react';
import {Link as RRLink} from 'react-router-dom';
import {Button, Breadcrumbs, Link, Typography} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartLine from './CartLine';
import {centsToDollars} from '../../common/utils';
import HelmetWrapper from './HelmetWrapper';

const Cart = ({
  menu, cart, cartDispatch, showBreadcrumbs, hideButton, small, tax, total
}) => {
  const cartLines = Object.values(cart.cartLines).map((line) => {
    const handleDeleteLine = (e) => {
      cartDispatch({type: 'deleteLine', payload: {product: line.product}});
    }
    return (
      <CartLine
        line={line}
        menu={menu}
        key={line.product}
        handleDeleteLine={handleDeleteLine}
        cart={cart} cartDispatch={cartDispatch}
        small={small}
      />
    );
  });

  return (
    <div className="cart">
      {showBreadcrumbs && <HelmetWrapper title="Cart" />}
      {showBreadcrumbs ?
      (
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RRLink} color="inherit" to="/menu">Menu</Link>
          <Typography variant="body1" color="textPrimary">Cart</Typography>
        </Breadcrumbs>
      ) : (
          <Typography component="h3" variant="h5" color="textSecondary">
            Cart
          </Typography>
      )
      }
      <ul className="cartlist">
        {cartLines}
      </ul>
      <div>
        <Typography
          variant="body1"
          color={total ? 'textSecondary' : 'textPrimary'}
        >
          Subtotal: ${centsToDollars(cart.subtotal)}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {tax && `Tax: $${tax}`}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {total && `Total: $${total}`}
        </Typography>
        {cart.sidesRemaining > 0 && <Typography variant="body1" color="warning">
          {`You have ${cart.sidesRemaining} more free sides!`}
        </Typography>}
      </div>
      {!hideButton &&
        <Button
          component={RRLink}
          variant="contained"
          color="secondary"
          className="order-button"
          startIcon={<ShoppingCartIcon />}
          to="/order/order-form"
        >
          Checkout
        </Button>
      }
    </div>
  );
}
export default Cart;