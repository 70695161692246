import React, {useState} from 'react';

const Image = ({image, height, alt, fallbackImage, ErrorComponent, error}) => {
  const [imageError, setImageError] = useState(error);
  return (
    !imageError ? 
      <picture className="picture">
        <source srcSet={image} type="image/webp" />
        <img
          src={fallbackImage} alt={alt}
          height={height} loading="lazy"
          onError={e => setImageError(true)}
        />
      </picture>
     : <ErrorComponent className="svg" />
  );
}
export default Image;