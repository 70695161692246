import {useReducer, useEffect} from 'react';
import {cartReducer, baseCart} from '../common/cartReducer';

export const useCartData = () => {
  const [state, dispatch] = useReducer(
    cartReducer,
    JSON.parse(sessionStorage.getItem('cart')) || baseCart
  );

  useEffect(() => {
    sessionStorage.setItem('cart', JSON.stringify(state));
  }, [state]);

  return [state, dispatch];
}
