import React from 'react';
import { Typography } from '@mui/material';

const ErrorFallback = ({error}) => {
  const errorMessage = (
    navigator.onLine ? `A ${error.name} occured.`
    : 'We could not connect to the internet.'
  )
  console.error(error.message);

  return (
    <div>
      <Typography variant="h2" component="p" align="center">
        An error occured.
      </Typography>
      <Typography variant="body1" align="center">{errorMessage}</Typography>
    </div>
  )
}
export default ErrorFallback;