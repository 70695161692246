import React from 'react';
import {Typography, Badge, Avatar, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToCart from './AddToCart';
import Image from './Image';
import {centsToDollars, dollarsToCents} from '../../common/utils';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';

const CartLine = ({line, menu, handleDeleteLine, cartDispatch, cart, small}) => {
	const product = menu.products.find(product => product.slug === line.product);
	const price = dollarsToCents(product.price);

	const onQuantityChange = (e) => {
		cartDispatch({
			type: 'updateLine', payload: {key: line.key, quantity: e.target.value}
		})
	}

  const sideUsed = cart.sidesUsed[line.product];

	return (
      <li key={line.key} className="cartline">
          <div className={`list-contents${small ? '-small' : ''}`}>
      <Badge badgeContent={`\u00D7${line.quantity}`}>
        <Avatar className="avatar">
          <Image
            height="30"
            image={product.image}
            fallbackImage={product.fallback_image}
            ErrorComponent={FastfoodRoundedIcon}
            error={!product.image}
            alt={product.name}
          />
        </Avatar>
      </Badge>
      <div className="list-text">
        <Typography color="textPrimary" variant="body1">
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          color={sideUsed ? "textSecondary" : "textPrimary"}
          className={sideUsed ? 'strike' : ''}
        >
          ${centsToDollars(price * line.quantity)}
        </Typography>
        {sideUsed && <Typography
          variant="body2"
          color="textPrimary"
        >
          ${centsToDollars(price * line.quantity - sideUsed.discount)}
        </Typography>}
      </div>
    </div>
    <div className={`add-to-cart${small ? '-small' : ''}`}>
    <AddToCart
      product={product}
      cart={cart}
      cartDispatch={cartDispatch}
    />
    </div>
    <IconButton edge="end" onClick={handleDeleteLine} size="large">
      <DeleteIcon />
    </IconButton>
      </li>
    );
}
export default CartLine;