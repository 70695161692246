import {centsToDollars, dollarsToCents} from './utils';

export const getFlattened = lines => {
  let newLines = [];
  for (const line of lines) {
    const quantity = line.quantity;
    for(let i = 0; i < quantity; i++) {
      newLines.push(line);
    }
  }
  newLines.sort((a, b) => {
    return dollarsToCents(b.price) - dollarsToCents(a.price)
  })
  return newLines
}

const getSides = lines => {
  return lines.reduce(
    (acc, curr) =>  (
      curr.sides * parseInt(curr.quantity, 10) + acc
    ), 0
  );
}

const sidesUsed = lines => {
  const sidelines = lines.filter(line => line.isSide === true);
  const flattened = getFlattened(sidelines);
  const sidesAvailable = Math.min(getSides(lines), flattened.length);
  const newFlattened = flattened.slice(0, sidesAvailable);
  const dict = {};
  for (const line of newFlattened) {
    if (dict[line.product]) {
      const curr = dict[line.product];
      curr.quantity++;
      curr.discount = dollarsToCents(line.price) * curr.quantity;
    } else {
      dict[line.product] = {quantity: 1, discount: dollarsToCents(line.price)};
    }
  }
  return dict;
}

export const getSubtotal = lines => {
  const subSubtotal = lines.reduce((acc, curr) => (
    dollarsToCents(curr.price) * parseInt(curr.quantity, 10) + acc
  ), 0);
  const sidesPrice = Object.values(sidesUsed(lines)).reduce((acc, curr) => (
    curr.discount + acc
  ), 0);
  return subSubtotal - sidesPrice;
}

const getQuantity = lines => {
  return lines.reduce(
    (acc, curr) => parseInt(curr.quantity, 10) + acc, 0
  )
  }

const generateState =(cartLines) => {
  const linesList = Object.values(cartLines);
  return {
    cartLines: cartLines,
    quantity: getQuantity(linesList),
    subtotal: getSubtotal(linesList),
    sides: getSides(linesList),
    sidesUsed: sidesUsed(linesList),
    get sidesRemaining() {
      return this.sides - Object.values(this.sidesUsed).reduce((acc, curr) => (
        curr.quantity + acc
      ), 0);
    }
  }
}

export const baseCart = {cartLines: {}, subtotal: 0, quantity: 0, sides: 0}

export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'newLine': {
      const product = action.payload.product;
      const cartLines = {...state.cartLines, [product]: action.payload};
      return generateState(cartLines);
    }
    case 'setLine': {
      const newLine = action.payload;
      const product = newLine.product;
      if (!state.cartLines[product] && newLine.quantity === '0') {
          return state;
      }
      const cartLines = {...state.cartLines};
      if (newLine.quantity === '0') {
        delete cartLines[newLine.product];
      }
      else {
        cartLines[newLine.product] = newLine;
      }
      return generateState(cartLines);
    }
    case 'increment': {
      const cartLines = {...state.cartLines};
      const quantity = parseInt(cartLines[action.payload.product].quantity, 10);
      cartLines[action.payload.product].quantity = (quantity + 1).toString();
      return generateState(cartLines);
    }
    case 'decrement': {
      const cartLines = {...state.cartLines};
      const workingLine = cartLines[action.payload.product];
      workingLine.quantity = (parseInt(workingLine.quantity, 10) - 1).toString();
      if (workingLine.quantity == 0) {
        delete cartLines[action.payload.product];
      }
      return generateState(cartLines);
    }
    case 'deleteLine': {
      const cartLines = {...state.cartLines};
      delete cartLines[action.payload.product];
      return generateState(cartLines);
    }
  
    case 'clearCart': {
      return baseCart;
    }
  }
}