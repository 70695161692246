import { createTheme} from '@mui/material/styles';

const titleFonts = ['Crimson Text', 'Roboto', 'serif'].join(',');

const theme = createTheme({
  palette: {
    primary: {
      main: '#a28d88',
      // main: '#4e342e',
    },
    secondary: {
      main: '#c62b02',
    },
  },
  typography: {
    h1: {
      fontFamily: titleFonts,
      fontWeight: 400,
    },
    h2: {
      fontFamily: titleFonts,
      fontWeight: 400,
    },
    h3: {
      fontFamily: titleFonts,
    },
    h4: {
      fontFamily: titleFonts,
    },
    h5: {
      fontFamily: titleFonts,
    },
    h6: {
      fontFamily: titleFonts,
      fontWeight: 600,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
              margin: '16px',
          },
        },
      },
    },
    MuiDateTimePicker: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
              margin: '16px',
          },
        },
      },
    }
  }
});
export default theme;