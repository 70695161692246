import React from 'react';
import {Button, IconButton} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const AddToCart = ({product, cart, cartDispatch}) => {
  const slug = product.slug;
  const quantity = cart.cartLines[slug] ? cart.cartLines[slug].quantity : 0;
  const disabled = !product.available;

  const handleChange = e => {
    const value = e.target.value;
    if (!isNaN(value, 10) || value === '') {
      console.log(`sides -> ${product.sides}`);
      cartDispatch({type: 'setLine', payload: {
        product: slug,
        quantity: value || '0',
        price: product.price,
        sides: product.sides
      }});
    }
  }

  return quantity != 0 ? (
    <div className="quantity-control">
      <IconButton
        disabled={disabled}
        color="secondary"
        onClick={e => cartDispatch(
          {type: 'decrement', payload: {product: slug}}
        )}
        size="large">
        <ArrowDropDownIcon />
      </IconButton>
      <input
        disabled={disabled}
        type="text"
        min="0"
        step="1"
        pattern="[0-9]*"
        inputMode="numeric"
        placeholder="quantity"
        value={quantity}
        onChange={handleChange}
        onFocus={e => e.target.select()}
      />
      <IconButton
        disabled={disabled}
        color="secondary"
        onClick={e => cartDispatch(
          {type: 'increment', payload: {product: slug}}
        )}
        size="large">
        <ArrowDropUpIcon />
      </IconButton>
  </div>
  ) : (
    <Button
      disabled={disabled}
      variant="contained"  
      color="primary"
      onClick={() => cartDispatch({type: 'setLine', payload: {
        product: slug,
        quantity: '1',
        price: product.price,
        sides: product.sides,
        isSide: product.category === 'sides'
      }})}
    >
      <AddShoppingCartIcon /> Add to cart
    </Button>
  );
}
export default AddToCart;