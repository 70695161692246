import {parseISO, format} from 'date-fns';

export const dollarsToCents = (dollars) => {
	const numbers = dollars.split('.').map(n => Number(n));
	return numbers[0] * 100 + numbers[1];
}

export const centsToDollars = (cents) => {
  const centsStr = cents.toString();
  return `${centsStr.slice(0, -2)}.${centsStr.slice(-2).padStart(2, '0')}`;
}

export const strToPercent = (str) => {
  const parts = str.split('.');
  let leading = Number(parts[0] * 100);
  leading = (leading + Number(parts[1].padEnd(2, '0').slice(0, 2))).toString();
  const decimal = parts[1].slice(2);
  return (Number(decimal)) ? `${leading}.${decimal}` : leading;
}

export const filterFunc = (line, i, lines) => {
  return line.product.id === lines[0].product.id;
}

export const combineLines = (lines, result=[]) => {
  if (!lines.length) {
    return result;
  }
  const newLines = lines.filter((e, i, a) => !filterFunc(e, i, a));
  const matching = lines.filter(filterFunc);
  const quantity = matching.reduce((acc, curr) => acc + curr.quantity, 0);
  let newLine = {...matching[0]};
  newLine.quantity = quantity;
  return combineLines(newLines, result.concat(newLine));
}

export const validateLuhn = (card) => {
  const cardNumber = [...card].reverse();
  const sum =  cardNumber.reduce((acc, curr, index) => {
    let number = parseInt(curr);
    if ((index % 2) != 0) {
      number *= 2;
      number = number > 9 ? number - 9 : number;
    }
    return acc + number;
  }, 0);
  return (sum % 10) === 0;
}

export const formatPhone = (num) => {
  return `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`;
}

export const formatDatetime = (iso) => {
  const datetime = parseISO(iso);
  return format(datetime, 'EEE LLL d, p');
}