import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './components/App';
import theme from '../common/theme';

const root = createRoot(document.getElementById('root'));
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>
);

if ('serviceWorker' in navigator) {
    const {Workbox} = await import('workbox-window');
    const wb = new Workbox('/sw.js', {scope: '/kitchen'});
    wb.register();
}
